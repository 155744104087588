import i18next from 'i18next';
import { useSyncExternalStore } from 'react';

import { SyncExternalStore } from '@egr/xbox/utils/SyncExternalStore';
import { mediaQueryMatch } from '@egr/xbox/utils/UserAgent';

export type AppTheme = 'auto' | 'light' | 'dark';

const darkmodeMediaQuery: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

interface LayoutHandlerState {
    darkmode: boolean;
    layoutMode: AppTheme;
}

class LayoutHandler extends SyncExternalStore<LayoutHandlerState>{
    constructor(state: LayoutHandlerState) {
        super(state);

        try {
            // Chrome & Firefox
            darkmodeMediaQuery.addEventListener('change', (event: MediaQueryListEvent) => {
                this.updateState({ darkmode: event.matches });
            });
        } catch {
            try {
                // Safari
                darkmodeMediaQuery.addListener((event: MediaQueryListEvent) => {
                    this.updateState({ darkmode: event.matches });
                });
            } catch {
                /* */
            }
        }
    }

    /** in react components use layoutInformation.hook().isDarkmodeActive instead */
    public get isDarkmodeActive(): boolean {
        const { layoutMode, darkmode } = this.snapshot;
        switch (layoutMode) {
            case 'auto':
                return darkmode;
            default:
                return layoutMode === 'dark';
        }
    }

    protected override hook = (): LayoutHandlerState & { isDarkmodeActive: boolean } => {
        const state = useSyncExternalStore(this.subscribe, this.getSnapshot);
        return {
            ...state,
            isDarkmodeActive: state.layoutMode === 'auto' ? state.darkmode : state.layoutMode === 'dark'
        };
    };

}

export const layoutInformation: LayoutHandler = new LayoutHandler({
    darkmode: mediaQueryMatch('(prefers-color-scheme: dark)'),
    layoutMode: 'auto',
});

export function layoutModeToLabel(layout: AppTheme | undefined): string {
    switch (layout) {
        case 'dark': return i18next.t('Dark');
        case 'light': return i18next.t('Light');
        default: return i18next.t('Automatic');
    }
}

/** for mobile app use callback in @egr/xbox/app-api/Utils */
export function validLayout(layout: AppTheme | undefined): AppTheme {
    switch (layout) {
        case 'dark': return 'dark';
        case 'light': return 'light';
        default:
            return 'light';
    }
}